import React from "react"
import Img from 'gatsby-image'
import { graphql } from 'gatsby'
import styled from 'styled-components'

import SEO from '../components/SEO'
import Layout from '../components/layout/layout'
import BanerProduct from '../components/banerProduct/BanerProduct';
import CardVertical, { CardWrapper } from "../components/cardVertical/CardVertical"
import { Container } from "../components/utils/utils"

const Article = styled(Container)`
h4 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

>div {
  margin-bottom: 2rem;
  margin-top: 2rem;
}
`;

const Neo = ({ data }) => {

  const card12 = data.card12.childImageSharp.fluid
  const card22 = data.card22.childImageSharp.fluid
  const card32 = data.card32.childImageSharp.fluid
  const card11 = data.card11.childImageSharp.fluid
  const card21 = data.card21.childImageSharp.fluid
  const card31 = data.card31.childImageSharp.fluid
  const card13 = data.card13.childImageSharp.fluid
  const card23 = data.card23.childImageSharp.fluid
  const card33 = data.card33.childImageSharp.fluid
  const card14 = data.card14.childImageSharp.fluid
  const card24 = data.card24.childImageSharp.fluid
  const card34 = data.card34.childImageSharp.fluid
  const n1 = data.n1.childImageSharp.fluid
  const n2 = data.n2.childImageSharp.fluid
  const n3 = data.n3.childImageSharp.fluid
  const n4 = data.n4.childImageSharp.fluid

  return <div>
    <SEO title="Neo Spectra"
      slug='/neo' />
    <Layout>

      <Article>
        <h2>Dziesięciolecia innowacyjnych osiągnięć w materiałach kompozytowych pozwoliły nam zrozumieć co ma największe znaczenie dla stomatologów.</h2>
      </Article>
      <CardWrapper>
        <CardVertical
          header1='Aplikacja'
          header2='Znakomite właściwości aplikacyjne'
          fluid={card13}
          fluid2={card14}>
          <p>
            Kształt i proporcje rozmiarów wypełniaczy SphereTEC®
            umożliwiają powstanie efektu łożyska kulkowego,
            który zapewnia doskonałą adaptację materiału w ubytku
            i jego wysoką plastyczność przestrzenną. Wypełniacze wiążą też
            więcej wolnej żywicy niż ma to miejsce w przypadku tradycyjnych
            wypełniaczy, co ogranicza lepkość materiału.
            </p>
        </CardVertical>

        <CardVertical
          header1='Estetyka'
          header2='Prosta droga do wysokiej estetyki'
          fluid={card23}
          fluid2={card24}>
          <p>
            Osiągnięcie naturalnego efektu estetycznego powinno być łatwe
            do uzyskania. Wypełniacze SphereTEC® stworzono w unikalny
            sposób, który pozwala im równoważyć opakerowość, przezierność,
            pochłanianie i rozpraszanie światła, zapewniając doskonały
            efekt kameleona. Mikrobudowa cząsteczek umożliwia
            szybkie osiąganie wysokiego połysku podczas polerowania.
          </p>
        </CardVertical>

        <CardVertical
          header1='Trwałość'
          header2='Niezawodna trwałość'
          fluid={card33}
          fluid2={card34}>
          <p>
            Wysokie zagęszczenie wypełniaczy oraz mikromechaniczne wiązanie
            przez nie żywicy odpowiadają za wyższą wytrzymałość i trwałość
            materiału. Wypełnione żywicą, wstępnie polimeryzowane wypełniacze
            zapewniają materiałowi wyjątkową odporność na odbarwienia.
          </p>
        </CardVertical>
      </CardWrapper>

      <Article>
        <h2>Neo Spectra™ST – nowe rozwiązanie w	odbudowach bezpośrednich oparte na technologii SphereTEC®</h2>
      </Article>

      <CardWrapper>
        <CardVertical
          header1='Neo Spectra™ST'
          header2='Uniwersalne odbudowy we wszystkich klasach ubytków'
          fluid={card11}
          fluid2={card12}>
          <ul>
            <li>znakomite właściwości adaptacyjne</li>
            <li>{'2 rodzaje konsystencji\n(do wyboru gęsta – HV lub miękka LV)'}</li>
            <li>{'optymalny system doboru koloru\n(5 odcieni pokrywających skalę VITA)'}</li>
          </ul>
        </CardVertical>

        <CardVertical
          header1='Neo Spectra™ST Effects'
          header2='Uzupełniający system dla odbudowy estetycznej'
          fluid={card21}
          fluid2={card22}>
          <ul>
            <li>dodatkowe przezierności do pracy warstwowej(szkliwo, zębina)</li>
            <li>łatwo osiągalna estetyka</li>
            <li>szybkie i proste polerowanie</li>
          </ul>
        </CardVertical>

        <CardVertical
          header1='Neo Spectra™ST flow'
          header2='Płynny materiał kompozytowy do wszechstronnego zastosowania'
          fluid={card31}
          fluid2={card32}>
          <ul>Szeroki zakres wskazań klinicznych:
            <li>wyścielanie ubytków – jako pierwsza warstwa w ubytkachklasy I i II</li>
            <li>wypełnienia klasy V</li>
            <li>poszerzone lakowanie bruzd</li>
            <li>minimalnie inwazyjne wypełnienia klasy I i II w	obszarze nie obciążonym	siłami żucia</li>
            <li>minimalnie inwazyjne wypełnienia klasy III</li>
            <li>niewielkie naprawy odbudowy bezpośredniej lub pośredniej w połączeniu z właściwym środkiem wiążącym</li>
          </ul>
        </CardVertical>
      </CardWrapper>

      <Article>
        <h2>Znakomite właściwości aplikacyjne</h2>
      </Article>

      <BanerProduct
        fluid={n1}
        fluidMobile={n1}
        header1='Znakomite właściwości aplikacyjne'
        header2='Dwa uniwersalne warianty konsystencji kompozytu'>
        <p>Dzięki technologii wypełniaczy SphereTEC® materiał kompozytowy
        Neo Spectra™ ST zapewnia optymalną aplikację w kremowej,
        łatwej do rozprowadzenia konsystencji o niskiej lepkości (LV) lub zbitej,
        łatwej do upakowania konsystencji o wysokiej lepkości (HV)
        zależnie od Twoich preferencji i techniki pracy.
        </p>
        <ul>Neo Spectra:
          <li>Nie lepi się do instrumentów</li>
          <li>Łatwo adaptuje się do powierzchni ubytku</li>
          <li>Łatwo pozwala się modelować</li>
          <li>Jest odporny na spływanie</li>
        </ul>
      </BanerProduct>

      <BanerProduct
        fluid={n2}
        fluidMobile={n2}
        header1='Znakomite właściwości aplikacyjne'
        header2='Wszechstronne zastosowanie płynnego kompozytu  
        Neo Spectra™ ST flow'>
        <p>W przypadkach tradycyjnych wskazań do wykorzystania płynnego
        kompozytu materiał Neo Spectra™ ST flow zapewnia efektywne
        osiąganie doskonałych rezultatów.
        </p>
        <ul>
          <li>Kontrolowana i precyzyjna aplikacja</li>
          <li>Szeroka gama kolorystyczna</li>
          <li>Wysoka trwałość dzięki technologii wypełniaczy SphereTEC®</li>
        </ul>
      </BanerProduct>

      <Article>
        <h2>Prostsza droga do estetycznej odbudowy</h2>
        <h4>Uniwersalne odcienie do większości przypadków </h4>
        <p>
          Pre-polimeryzowane cząsteczki wypełniaczy SphereTEC®
          w połączeniu z żywicą kompozytową dają doskonały efekt kameleona.
        </p>
        <ul>
          <li>5 uniwersalnych odcieni odpowiada wszystkim kolorom kolornika VITA® 1</li>
          <li>Niezawodne, precyzyjne dopasowanie koloru do barwy zębów sąsiadujących</li>
          <li>Dostępny odcień BW do odbudowy zębów wybielanych</li>
        </ul>

        <Img
          fluid={n3}
          alt='NEO Spectra' />

        <h4>Uzupełniające odcienie zębiny i szkliwa do odbudowy estetycznej</h4>
        <p>
          W zębach przednich, które wymagają wyższego poziomu estetyki,
          odcienie Neo Spectra™ ST Effects i uniwersalne odcienie Neo Spectra™ ST
          tworzą razem system do pracy uproszczoną techniką warstwową.
        </p>
        <ul>
          <li>Opakerowe odcienie zębinowe</li>
          <li>Dodatkowy odcień szkliwa do brzegów siecznych</li>
          <li>Prosta technika pracy pozwalająca osiągnąć jeszcze lepsze rezultaty estetyczne</li>
        </ul>

        <Img
          fluid={n4}
          alt='NEO Spectra' />

      </Article>

    </Layout>
  </div>
}

export default Neo

export const query = graphql`
query {
  img404: file(relativePath: { eq: "main_image2.jpg" }) {
    childImageSharp {
      fluid(quality: 100, maxWidth: 1000) {
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }

  card12: file(relativePath: { eq: "neo/card12.png" }) {
    childImageSharp {
      fluid(quality: 100, maxWidth: 300) {
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }

  card22: file(relativePath: { eq: "neo/card22.png" }) {
    childImageSharp {
      fluid(quality: 100, maxWidth: 300) {
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }

  card32: file(relativePath: { eq: "neo/card32.png" }) {
    childImageSharp {
      fluid(quality: 100, maxWidth: 300) {
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }

  card11: file(relativePath: { eq: "neo/card11.png" }) {
    childImageSharp {
      fluid(quality: 100, maxWidth: 300) {
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }

  card21: file(relativePath: { eq: "neo/card21.png" }) {
    childImageSharp {
      fluid(quality: 100, maxWidth: 300) {
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }

  card31: file(relativePath: { eq: "neo/card31.png" }) {
    childImageSharp {
      fluid(quality: 100, maxWidth: 300) {
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }

  card13: file(relativePath: { eq: "neo/card13.png" }) {
    childImageSharp {
      fluid(quality: 100, maxWidth: 300) {
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }

  card23: file(relativePath: { eq: "neo/card23.png" }) {
    childImageSharp {
      fluid(quality: 100, maxWidth: 300) {
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }

  card33: file(relativePath: { eq: "neo/card33.png" }) {
    childImageSharp {
      fluid(quality: 100, maxWidth: 300) {
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }

  card14: file(relativePath: { eq: "neo/card14.png" }) {
    childImageSharp {
      fluid(quality: 100, maxWidth: 300) {
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }

  card24: file(relativePath: { eq: "neo/card24.png" }) {
    childImageSharp {
      fluid(quality: 100, maxWidth: 300) {
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }

  card34: file(relativePath: { eq: "neo/card34.png" }) {
    childImageSharp {
      fluid(quality: 100, maxWidth: 300) {
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }

  n1: file(relativePath: { eq: "neo/n1.png" }) {
    childImageSharp {
      fluid(quality: 100, maxWidth: 500) {
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }

  n2: file(relativePath: { eq: "neo/n2.png" }) {
    childImageSharp {
      fluid(quality: 100, maxWidth: 500) {
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }

  n3: file(relativePath: { eq: "neo/n3.png" }) {
    childImageSharp {
      fluid(quality: 100, maxWidth: 1500) {
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }

  n4: file(relativePath: { eq: "neo/n4.png" }) {
    childImageSharp {
      fluid(quality: 100, maxWidth: 1500) {
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }
}
`